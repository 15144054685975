
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    data(): any {
        return {
            rendererUrl: process.env.VUE_APP_RENDERER_URL,
            items: {
                data: [],
                meta: {},
            },
            params: {
                filter: '',
                page: 1,
                sortBy: 'created_at',
                orderBy: 'desc',
                tags: [],
            },
        };
    },
    mounted(): void {
        this.getItems();
    },
    methods: {
        getItems(page: number = 1): void {
            this.params.page = page;
            this.get('platforms', {
                params: {
                    ...this.params,
                },
            })
                .then(({ data }: any) => {
                    this.items = data;
                });
        },
    },
});
